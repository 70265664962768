.contactForm {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  width: 50%;
  margin: auto;
  background-color: azure;
  /* border-top: 1px solid #ccc; */

  /* max-width: 60rem; */
}
.main {
  border: 2px solid #000;
}
.image {
  width: 100%;
  height: 270px;
}
.submitBtn {
  background: (rgb(7, 6, 6) 5, 31, 31);
  border: none;
  padding: 0.75rem 3.5rem;
  margin: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.inputField {
  margin-bottom: 10px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* margin-left: 40px; */
}

button:hover {
  background-color: #0056b3;
}
input {
  height: 35px;
}
input,
textarea {
  width: 100%;
}

.contactForm1 {
  padding: 20px;
}

.row3 {
  display: flex;
  gap: 25px;
}

.btnCenter {
  width: 100%;
  margin: auto;
}
.ack {
  display: flex;
  gap: 20px;
}
p {
  text-align: center;
}
.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.us {
  text-decoration: underline;
}
.link {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
  cursor: pointer;
}
.required {
  color: red;
  margin-left: 4px;
}

.edu select {
  width: 100%;
}
@media (max-width: 480px) and (min-width: 360px) {
  .contactForm {
    width: 90%;
  }
  .row3 {
    display: block;
  }
  .row3 select {
    width: 100%;
  }
}
